<template>
  <div id="subscriptions">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Header />
    </v-container>
    <v-container
      fluid
      class="grey lighten-5"
    >
      <v-card class="py-4 px-2">
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              label="Batch Name"
              placeholder="Batch Name"
              outlined
              clearable
              dense
              v-model="filter.batchName"
              @keydown.enter="generateSubReport"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              clearable
              dense
              label="School"
              :items="schools"
              item-text="rec"
              item-value="code"
              v-model="filter.subscriber"
              prepend-inner-icon="mdi-school"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              clearable
              dense
              label="Supplier"
              :items="suppliers"
              item-text="name"
              item-value="supplierId"
              v-model="filter.supplier"
              prepend-inner-icon="mdi-account-tie"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              clearable
              dense
              label="Status"
              :items="['Submitted', 'Initiated', 'Approved', 'Errored', 'Rejected']"
              v-model="filter.status"
              prepend-inner-icon="mdi-list-status"
            />
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :close-on-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :position-x="menuPositionX"
              :position-y="menuPositionY"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDateRange()"
                  label="Last Modified Date Range"
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  clearable
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  ref="dateInput"
                  @click:clear="clearDates"
                />
              </template>
              <v-date-picker
                v-model="filter.dateRange"
                range
                @change="handleDateChange"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="3"
            class="offset-3"
          >
            <v-btn
              block
              large
              color="primary"
              buttonText="Search"
              :disabled="disableGenerateBtn"
              @click="generateSubReport"
            >
              <v-icon class="mr-1">mdi-magnify</v-icon>
              Search
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              block
              large
              color="primary"
              :disabled="!subscriptionList.length"
              buttonText="Download"
              @click="saveSubscriptionsCsv"
            >
              <v-icon class="mr-1">mdi-download</v-icon>
              Download
            </v-btn>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row
            dense
            v-if="!isReadOnlyUser && datatable.length"
          >
            <v-col
              col="6"
              class="d-flex align-center"
            >
              <v-chip
                color="error"
                class="ml-5 mr-2"
              >
                {{ datatable.length }}
              </v-chip>
              <span>Selected</span>
            </v-col>
            <v-col
              cols="3"
              class="d-flex align-center"
            >
              <v-btn
                large
                block
                depressed
                color="success"
                buttonText="Bulk Approve"
                @click="
                  dialog = true
                  action = 'approve'
                "
              >
                <v-icon class="mr-1">mdi-check-circle</v-icon>
                Bulk Approve
              </v-btn>
            </v-col>
            <v-col
              cols="3"
              class="d-flex align-center"
            >
              <v-btn
                large
                block
                depressed
                color="error"
                buttonText="Bulk Reject"
                @click="
                  dialog = true
                  action = 'reject'
                "
              >
                <v-icon class="mr-1">mdi-close-circle</v-icon>
                Bulk Reject
              </v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600"
        >
          <v-card>
            <v-card-title class="text-h5">Bulk {{ capitalize(action) }}!</v-card-title>
            <v-card-text>
              <p style="color: black; font-weight: 500">
                Are you sure you want to
                <b :class="action === 'approve' ? 'text-green' : 'text-red'">{{ action }}</b>
                the
                <b>{{ datatable.length }}</b>
                selected subscription(s)?
              </p>
              <v-textarea
                v-if="action !== 'approve'"
                color="black"
                label="Comment"
                v-model="comment"
                outlined
                clearable
                rows="3"
                :rules="[validators.required, validators.minMax(0, 200)]"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                large
                @click="
                  dialog = false
                  comment = ''
                "
              >
                Cancel
              </v-btn>
              <v-btn
                large
                :color="action === 'approve' ? 'success' : 'error'"
                @click="bulkUpdate"
                :disabled="disableUpdateBtn"
              >
                {{ capitalize(action) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <div class="table-wrapper">
        <Alert
          :showAlert="lastEvaluatedKey"
          v-if="lastEvaluatedKey"
          type="info"
          allowDismiss
          absolute
          text="Your search returned 500 or more rows. Consider adding more filters to narrow down results"
          :inPage="true"
          :backgroundColour="true"
        />
        <v-data-table
          v-model="datatable"
          :headers="headers"
          :items="subscriptionList"
          :footer-props="{
            'items-per-page-options': itemPerPageOptions,
          }"
          :items-per-page="20"
          @click:row="openSubscription"
          :show-select="!isReadOnlyUser"
          item-key="subscriptionReqId"
          :item-class="rowClass"
          :search="search"
          class="elevation-3 sub-req-table"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Find subscriptions"
              class="mx-4"
            />
          </template>
          <template v-slot:item.batchName="{ item }">
            <div
              class="batchLink"
              v-if="item.batchName"
            >
              {{ item.batchName }}
            </div>
          </template>
          <template v-slot:item.orderNumber="{ item }">
            <div class="hyperLink">
              {{ item.isOrderNoTypeOther ? 'Other' : item.orderNumber }}
            </div>
          </template>
          <template v-slot:item.requestType="{ item }">
            <v-chip
              :color="getTypeChipColor(item.requestType).background"
              :text-color="getTypeChipColor(item.requestType).color"
            >
              {{ item.requestType }}
            </v-chip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="getStatusChipColor(item.status).background"
              :text-color="getStatusChipColor(item.status).color"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:item.actionedAt="{ item }">
            {{ item.lastModified }}
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>
<script>
import { Alert } from '@nswdoe/doe-ui-core'
import csvDownload from 'json-to-csv-export'
import dayjs from 'dayjs'
import Header from '@/views/SubscriptionRequests/Header/Header'
import { isEmpty, capitalize } from 'lodash'
import validators from '@/helpers/validators'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const itemPerPageOptions = [20, 30, 50, -1]
const headers = [
  { text: 'Batch Name', value: 'batchName' },
  { text: 'Order Number/Other', value: 'orderNumber' },
  { text: 'Supplier', value: 'supplierName' },
  { text: 'Product', value: 'productName' },
  { text: 'Finance Exclusive', value: 'isFinancial', align: 'center' },
  { text: 'Subscriber', value: 'subscriber' },
  { text: 'School code', value: 'schoolCode' },
  { text: 'Start Date', value: 'startDate' },
  { text: 'Expiry Date', value: 'endDate' },
  { text: 'Type', value: 'requestType' },
  { text: 'Status', value: 'status' },
  { text: 'Requested By', value: 'createdBy'},
  { text: 'Requested Date', value: 'createdAt' },
  { text: 'Actioned By', value: 'actionedBy' },
  { text: 'Last Modified Date', value: 'lastModified' },
]

export default {
  name: 'SubscriptionsRequestListing',
  title: 'Subscription Requests - SAIS Operation UI Portal',
  components: {
    Alert,
    Header,
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    schools() {
      return this.$store.state.moduleSchools.schools || []
    },
    suppliers() {
      return this.$store.state.moduleSuppliers.suppliers || []
    },
    disableCsvBtn() {
      return !this.subscriptionList.length
    },
    disableGenerateBtn() {
      return !this.filter.supplier && !this.filter.subscriber
    },

    disableUpdateBtn() {
      if (this.action === 'approve') {
        return !!(this.comment && this.comment?.length > 200)
      }
      return !this.comment || this.comment?.length > 200
    },
    filter() {
      return this.$store.state.moduleSubscriptionRequests.filter
    },
  },
  async created() {
    if (
      !this.$store.state.moduleSuppliers.suppliers ||
      this.$store.state.moduleSuppliers.suppliers.length === 0
    ) {
      await this.$store.dispatch('moduleSuppliers/fetchSuppliers')
    }
    if (
      !this.$store.state.moduleSchools.schools ||
      this.$store.state.moduleSchools.schools.length === 0
    ) {
      await this.$store.dispatch('moduleSchools/fetchSchools')
    }

    if (this.$store.state.moduleSubscriptionRequests.subscriptionRequests.length > 0) {
      this.formatData()
    }
    const { batch, supplierId } = this.$route.query

    if (batch && supplierId) {
      this.filter.batchName = batch
      this.filter.supplier = supplierId
      this.generateSubReport()
    }
  },
  data() {
    return {
      datatable: [],
      search: '',
      headers,
      itemPerPageOptions,
      fetchingSubList: false,
      lastEvaluatedKey: false,
      dialog: false,
      action: '',
      comment: '',
      subscriptionList: [],
      menu: false,
      menuPositionX: 0,
      menuPositionY: 0,
      validators: {
        required: validators.required,
        minMax: validators.minMaxString,
      },
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateMenuPosition)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMenuPosition)
  },
  watch: {
    menu(val) {
      if (val) {
        this.$nextTick(this.updateMenuPosition)
      }
    },
  },
  methods: {
    capitalize(str) {
      return capitalize(str)
    },
    rowClass(item) {
      return item.status !== 'Submitted' ? 'disabled-row' : ''
    },
    clearDates() {
      this.filter.dateRange = []
      this.menu = false
    },
    formatDateRange() {
      if (!this.filter.dateRange || isEmpty(this.filter.dateRange)) return null
      return (
        this.filter.dateRange
          .map((date) => (date ? dayjs(date).format('DD/MM/YYYY') : ''))
          .join(' - ') || null
      )
    },
    updateMenuPosition() {
      if (!this.$refs.dateInput) return

      const inputRect = this.$refs.dateInput.$el.getBoundingClientRect()
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      // Determine X position
      if (inputRect.left + 290 > windowWidth) {
        // Align to right edge if it would overflow on the right
        this.menuPositionX = windowWidth - 290
      } else {
        // Otherwise, align with the input field
        this.menuPositionX = inputRect.left
      }

      // Determine Y position
      if (inputRect.bottom + 320 > windowHeight) {
        // Position above the input if it would overflow at the bottom
        this.menuPositionY = inputRect.top - 320
      } else {
        // Otherwise, position below the input
        this.menuPositionY = inputRect.bottom
      }
    },
    handleDateChange(dates) {
      if (dates.length === 2) {
        // Ensure dates are in chronological order
        const [date1, date2] = dates.map((d) => new Date(d))
        if (date1 > date2) {
          this.filter.dateRange = [dates[1], dates[0]]
        } else {
          this.filter.dateRange = dates
        }
        this.$nextTick(() => {
          this.menu = false
        })
      } else {
        this.filter.dateRange = dates
      }
    },
    isDateBeforeOrEqualToday(date) {
      const today = dayjs().startOf('day') // Get today's date at midnight
      const end = dayjs(date).startOf('day') // Ensure comparison ignores time

      return end.isBefore(today) || end.isSame(today)
    },
    formatData() {
      this.subscriptionList = this.$store.state.moduleSubscriptionRequests.subscriptionRequests.map(
        (item) => {
          const allowedStatus = ['Submitted', 'Errored']
          const isSelectable = allowedStatus.includes(item.status) && !item.isFinancial

          return {
            isSelectable: isSelectable,
            orderNumber:
              item.justification.value + (item?.justification?.type === 'other' ? 'Other' : ''),
            isOrderNoTypeOther: item?.justification?.type === 'other',
            supplierName: item.supplierName,
            productName: item.productNames.join(', '),
            subscriber: this.schools.find((school) => school.code === item.schoolCode)?.name,
            schoolCode: item.schoolCode,
            startDate: item.startDate,
            endDate: item.endDate,
            status: item.status,
            isFinancial: item.isFinancial ? 'Yes' : 'No',
            requestType: item.requestType,
            createdBy: item.createdBy,
            createdAt: dayjs(item.createdAt).utc(true).format('DD/MM/YYYY HH:mm:ss'),
            actionedBy: item.updatedBy,
            actionedAt: item.updatedAt,
            lastModified: dayjs(item.updatedAt).utc(true).format('DD/MM/YYYY HH:mm:ss'),
            supplierId: item.supplierId,
            subscriptionReqId: item.subscriptionReqId,
            batchName: item.batchName || '',
            batchId: item.batchId || '',
          }
        }
      )
      this.lastEvaluatedKey = !!this.$store.state.moduleSubscriptionRequests.lastEvaluatedKey
    },
    generateSubReport() {
      this.datatable = []
      const { status, supplier, subscriber, dateRange, batchName } = this.filter
      if (!supplier && !subscriber) return
      const fromDate = dateRange[0]
        ? dayjs(dateRange[0]).startOf('day').utc(false).toISOString()
        : undefined
      let toDate = dateRange[1]
        ? dayjs(dateRange[1]).endOf('day').utc(false).toISOString()
        : undefined

      if (fromDate && !toDate) {
        toDate = dayjs(dateRange[0]).endOf('day').utc(false).toISOString()
      }

      let payload = {
        status,
        fromDate,
        toDate,
        supplierId: supplier,
        schoolCode: subscriber,
        batchName,
      }

      this.$store
        .dispatch('moduleSubscriptionRequests/fetchSubscriptionRequests', payload)
        .finally(() => {
          this.formatData()
        })
    },
    saveSubscriptionsCsv() {
      const data = this.subscriptionList.map((values) => {
        const {
          batchName,
          orderNumber,
          isOrderNoTypeOther,
          supplierName,
          productName,
          isFinancial,
          subscriber,
          schoolCode,
          startDate,
          endDate,
          requestType,
          status,
          createdBy,
          createdAt,
          actionedBy,
          lastModified,
        } = values
        return {
          batchName,
          orderNumber: isOrderNoTypeOther ? orderNumber.slice(0, -5) : orderNumber,
          supplierName,
          productName,
          isFinancial,
          subscriber,
          schoolCode,
          startDate,
          endDate,
          requestType,
          status,
          createdBy,
          createdAt,
          actionedBy,
          lastModified,
        }
      })

      csvDownload({
        data,
        filename: 'Subscription-Requests',
        delimiter: ',',
        headers: headers.map((header) => {
          return header.text
        }),
      })
    },
    bulkUpdate() {
      if (this.isReadOnlyUser) return
      const status = this.action === 'approve' ? 'approve' : 'reject'
      const subscriptionReqs = this.datatable.map((item) => ({
        subscriptionReqId: item.subscriptionReqId,
        supplierId: item.supplierId,
      }))
      const payload = {
        status,
        subscriptionReqs,
        rejectReason: this.comment || '',
      }
      this.dialog = false
      this.$store.dispatch('moduleSubscriptionRequests/bulkUpdate', payload).then(() => {
        this.formatData()
        this.comment = ''
        this.datatable = []
        this.subscriptionList = []
        this.generateSubReport()
      })
    },
    openSubscription(item, table, event) {
      if (event.target.classList.contains('hyperLink') || event.target.closest('.hyperLink')) {
        this.$router.push(
          `/serviceregistry/subscriptionrequests/view/${item.subscriptionReqId}?supplierId=${item.supplierId}`
        )
      }
      if (event.target.classList.contains('batchLink') || event.target.closest('.batchLink')) {
        this.$router.push(
          `/serviceregistry/batch-request/view/${item.batchId}?supplierId=${item.supplierId}&batchName=${item.batchName}`
        )
      }
    },
    getStatusChipColor(status) {
      switch (status) {
        case 'Submitted':
          return { background: '#FFC107', color: 'black' } // Amber-500
        case 'Initiated':
          return { background: '#2196F3', color: 'white' } // Blue-500
        case 'Approved':
          return { background: '#4CAF50', color: 'white' } // Green-500
        case 'Errored':
        case 'Rejected':
          return { background: '#F44336', color: 'white' } // Red-500
        default:
          return { background: '#757575', color: 'white' } // Grey-500
      }
    },
    getTypeChipColor(type) {
      switch (type) {
        case 'New':
          return { background: '#2196F3', color: 'white' } // Blue-500
        case 'Renewal':
          return { background: '#3F51B5', color: 'white' } // Indigo-500
        case 'Grace':
          return { background: '#FF9800', color: 'white' } // Orange-500
        default:
          return { background: '#E0E0E0', color: 'black' } // Grey-300
      }
    },
  },
}
</script>
<style scoped>
.table-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}
.sub-req-table {
  padding-top: 20px;
  padding-bottom: 20px;
}
.hyperLink,
.batchLink {
  color: #0351a5;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.hyperLink:hover,
.batchLink:hover {
  text-decoration: underline;
}
.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}
.text-red {
  color: red;
}
.text-green {
  color: green;
}
</style>
